var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-card',{attrs:{"body-style":{ padding: '24px 32px' },"bordered":false}},[_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"类型","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'classify',
        {
          rules: [{ required: true, message: '未选择类型' }],
          initialValue: _vm.info.classify
        }
      ]),expression:"[\n        'classify',\n        {\n          rules: [{ required: true, message: '未选择类型' }],\n          initialValue: info.classify\n        }\n      ]"}],attrs:{"placeholder":"请选择"}},_vm._l((_vm.type),function(option,key){return _c('a-select-option',{key:key,attrs:{"value":option}},[_vm._v(_vm._s(option))])}),1)],1),_c('a-form-item',{attrs:{"label":"标题","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'title',
        {
          rules: [{ required: true, message: '标题不能为空' }],
          initialValue: _vm.info.title
        },
      ]),expression:"[\n        'title',\n        {\n          rules: [{ required: true, message: '标题不能为空' }],\n          initialValue: info.title\n        },\n      ]"}],attrs:{"placeholder":"新闻标题"}})],1),_c('a-form-item',{attrs:{"label":"关键字","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'keywords',
        {
          rules: [{ required: true, message: '关键字不能为空' }],
          initialValue: _vm.info.keywords
        },
      ]),expression:"[\n        'keywords',\n        {\n          rules: [{ required: true, message: '关键字不能为空' }],\n          initialValue: info.keywords\n        },\n      ]"}],attrs:{"placeholder":"新闻关键字"}})],1),_c('a-form-item',{attrs:{"label":"描述","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'description',
        {
          rules: [{ required: true, message: '描述不能为空' }],
          initialValue: _vm.info.description
        },
      ]),expression:"[\n        'description',\n        {\n          rules: [{ required: true, message: '描述不能为空' }],\n          initialValue: info.description\n        },\n      ]"}],attrs:{"placeholder":"新闻描述"}})],1),_c('a-form-item',{attrs:{"label":"内容","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('quill-editor',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'contents',
        {
          rules: [{ required: true, message: '描述不能为空' }],
          initialValue: _vm.info.contents
        },
      ]),expression:"[\n        'contents',\n        {\n          rules: [{ required: true, message: '描述不能为空' }],\n          initialValue: info.contents\n        },\n      ]"}],on:{"change":_vm.quillEditorChange}})],1),_c('a-form-item',{attrs:{"label":"状态","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'status',
        {
          valuePropName: 'checked',
          initialValue: _vm.infoStatus,
        },
      ]),expression:"[\n        'status',\n        {\n          valuePropName: 'checked',\n          initialValue: infoStatus,\n        },\n      ]"}]},[_vm._v(" 是否展示 ")])],1),_c('a-form-item',{staticStyle:{"text-align":"center"},attrs:{"wrapperCol":{ span: 24 }}},[_c('a-button',{attrs:{"htmlType":"submit","loading":_vm.submitLoading,"type":"primary"}},[_vm._v("提交")]),_c('a-button',{staticStyle:{"margin-left":"8px"},on:{"click":_vm.routerback}},[_vm._v("返回")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }