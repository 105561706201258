<template>
  <a-card :body-style="{ padding: '24px 32px' }" :bordered="false">
    <a-form @submit="handleSubmit" :form="form">
      <a-form-item label="类型" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-select placeholder="请选择" v-decorator="[
          'classify',
          {
            rules: [{ required: true, message: '未选择类型' }],
            initialValue: info.classify
          }
        ]">
          <a-select-option v-for="(option, key) in type" :key="key" :value="option">{{ option }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="标题" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-input v-decorator="[
          'title',
          {
            rules: [{ required: true, message: '标题不能为空' }],
            initialValue: info.title
          },
        ]" placeholder="新闻标题" />
      </a-form-item>
      <a-form-item label="关键字" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-input v-decorator="[
          'keywords',
          {
            rules: [{ required: true, message: '关键字不能为空' }],
            initialValue: info.keywords
          },
        ]" placeholder="新闻关键字" />
      </a-form-item>
      <a-form-item label="描述" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-input v-decorator="[
          'description',
          {
            rules: [{ required: true, message: '描述不能为空' }],
            initialValue: info.description
          },
        ]" placeholder="新闻描述" />
      </a-form-item>
      <a-form-item label="内容" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <quill-editor v-decorator="[
          'contents',
          {
            rules: [{ required: true, message: '描述不能为空' }],
            initialValue: info.contents
          },
        ]" @change="quillEditorChange" />
      </a-form-item>
      <a-form-item label="状态" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-checkbox v-decorator="[
          'status',
          {
            valuePropName: 'checked',
            initialValue: infoStatus,
          },
        ]">
          是否展示
        </a-checkbox>
      </a-form-item>
      <a-form-item :wrapperCol="{ span: 24 }" style="text-align: center">
        <a-button htmlType="submit" :loading="submitLoading" type="primary">提交</a-button>
        <a-button style="margin-left: 8px" @click="routerback">返回</a-button>
      </a-form-item>
    </a-form>
  </a-card>
</template>

<script>
import { newsInfo, newsCreate, newsSave, newsAdditional } from '@/api/news'
import QuillEditor from '@/components/Editor/QuillEditor'

export default {
  name: 'NewsEdit',
  components: {
    QuillEditor
  },
  data() {
    return {
      description: '新闻编辑添加',
      value: 1,
      fontStyle: {
        fontWeight: 1000
      },
      labelCol: {
        lg: { span: 4 },
        md: { span: 4 },
        sm: { span: 24 }
      },
      wrapperCol: {
        lg: { span: 20 },
        md: { span: 20 },
        sm: { span: 24 }
      },
      queryData: {},
      type: {},
      info: {
        classify: '公司新闻'
      },
      infoStatus: false,
      pageType: 'add', // 当前页面是新增(add),还是修改(edit)

      // form
      form: this.$form.createForm(this),
      submitLoading: false,
      contents: ''
    }
  },
  created() {
    const id = this.$route.query.id
    this.queryData.id = id
    if (id !== undefined) {
      this.pageType = 'edit'
      this.loadInfo(id)
    } else {
      this.loadType()
    }
  },
  methods: {
    loadInfo(id) {
      new Promise((resolve, reject) => {
        newsInfo({ 'id': id }).then(response => {
          if (response.status === 'success') {
            resolve(response.data)
          } else {
            reject(response)
          }
        })
      }).then(data => {
        this.info = data.info
        if (data.info.status === 1) {
          this.infoStatus = true
        } else {
          this.infoStatus = false
        }
        this.type = data.news_classify_type
      }).catch(error => {
        this.$notification.error({
          message: '错误',
          description: error.message
        })
        // 跳转回
        // setTimeout(() => {
        //     this.$router.back(-1)
        // }, 2000)
      })
    },
    loadType() {
      new Promise((resolve, reject) => {
        newsAdditional().then(response => {
          if (response.status === 'success') {
            resolve(response.data)
          } else {
            reject(response)
          }
        })
      }).then(data => {
        this.type = data.news_classify_type
      }).catch(error => {
        this.$notification.error({
          message: '错误',
          description: error.message
        })
        // 跳转回
        setTimeout(() => {
          this.$router.back(-1)
        }, 2000)
      })
    },
    quillEditorChange(html) {
      this.contents = html
    },
    // handler
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          values.status = values.status ? 1 : 0
          if (this.pageType === 'edit') {
            new Promise((resolve, reject) => {
              this.submitLoading = true
              values.id = this.queryData.id
              newsSave(values).then(response => {
                if (response.status === 'success') {
                  resolve(response)
                } else {
                  reject(response)
                }
              })
            }).then(res => {
              this.$notification.success({
                message: res.status,
                description: res.message
              })
              // 跳转回列表页面
              setTimeout(() => {
                this.$router.back(-1)
              }, 2000)
            }).catch(error => {
              this.$notification.error({
                message: '错误',
                description: error.message
              })
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            // 新增
            new Promise((resolve, reject) => {
              this.submitLoading = true
              newsCreate(values).then(response => {
                if (response.status === 'success') {
                  resolve(response)
                } else {
                  reject(response)
                }
              })
            }).then(res => {
              this.$notification.success({
                message: res.status,
                description: res.message
              })
              // 跳转回列表
              setTimeout(() => {
                this.$router.back(-1)
              }, 2000)
            }).catch(error => {
              this.$notification.error({
                message: '错误',
                description: error.message
              })
            }).finally(() => {
              this.submitLoading = false
            })
          }
        }
      })
    },
    routerback: function () {
      this.$router.back(-1)
    }
  }
}
</script>
